"use strict";

$(window).on("load", () => {
    tabs();
    headerMenu();
    fixedHeader();
    dropdown();
    fields();
    filter();
    channelPin();
    channelComment();
    select();
    hTaskLayout();
    cChart();
    rangeSlider();
    scrollbar();
    passwordVisible();
    faqMenu();
    messageText();
    addFile();
    stickyElement();
    apiItem();
    apiToken();
    datepicker();
    sidebar();
    taskToggle();
    profileTabs();
    serviceItemHidden();
    popup();
    alert();
    confirmDialog();
    api();
    $(window).on("scroll", () => {
        fixedHeader();
    });
});

function apiToken() {
    $(".js-api-token-copy").on("click", function (e) {
        e.preventDefault();
        var $temp = $("<input>");
        $("body").append($temp);
        $temp.val($(".js-api-token").eq(0).text()).select();
        if (document.execCommand("copy")) {
            addAlert('success', 'Токен скопирован в буфер обмена.');
        }
        $temp.remove();
    });
}

function closeActivePopup() {
    var popup = $(".popup.active");
    popup.removeClass("animate");
    setTimeout(function () {
        popup.removeClass("active");
        if ($(window).innerWidth() < 768) {
            $(".pc-sidebar__head").removeClass("popuped");
            $(".pc-sidebar__popup-link-text").text();
            $(".pc-sidebar__popup-link").removeClass("js-popup-close");
        }
    }, 300);
}

function popup() {
    $(".js-popup-open").on("click", function (e) {
        e.preventDefault();
        var popup = $(this).attr("href");
        if ($(".pc-sidebar").hasClass("active")) {
            $(".js-pc-sidebar").trigger("click");
            setTimeout(function () {
                $(popup).addClass("active animate");
            }, 300);
        }
        else {
            $(popup).addClass("active animate");
        }
        if ($(window).innerWidth() < 768) {
            $(".pc-sidebar__head").addClass("popuped");
            $(".pc-sidebar__popup-link-text").text($(popup).find(".popup-title").text());
            $(".pc-sidebar__popup-link").addClass("js-popup-close");
        }
    });
    $(document).on("click", ".js-popup-close, .popup-overlay", function (e) {
        e.preventDefault();
        closeActivePopup();
    });
}

function serviceItemHidden() {
    $("[data-hidden]").each(function () {
        var id = "#" + $(this).attr("data-hidden");
        var hidden = $(this).find(".pc-service-item__hidden");
        if ($(id).is(":checked")) {
            hidden.addClass("active");
        }
        $("input[type=radio][name=\"" + $(id).attr("name") + "\"]").change(function () {
            if ("#" + $(this).attr("id") == id) {
                hidden.addClass("active");
            }
            else {
                hidden.removeClass("active");
            }
        })
    });
}

function profileTabs() {
    $(".js-profile-tabs-btn").on("click", function (e) {
        e.preventDefault();
        $(".js-profile-tabs-btn, .js-profile-tabs-item").removeClass("active");
        $(this).addClass("active");
        $(".js-profile-tabs-item").eq($(this).index()).addClass("active");
    })
}

function channelComment() {
    $(".pc-s-channel__comment-text").each(function () {
        if ($(this).find(".pc-s-channel__comment-text-content").innerHeight() <= 42) {
            $(this).addClass("active");
            $(this).find(".pc-s-channel__comment-text-btn").addClass("hidden");
        }
    })
    $(".js-channel-comment-toggle").on("click", function () {
        var text = $(this).closest(".pc-s-channel__comment-text");
        text.stop().toggleClass("active");
        if (text.hasClass("active")) {
            $(this).find(".pc-s-channel__comment-text-btn-text").text("Закрыть");
            text.find(".pc-s-channel__comment-text-inner").css("height", text.find(".pc-s-channel__comment-text-content").innerHeight());
        }
        else {
            $(this).find(".pc-s-channel__comment-text-btn-text").text("Показать больше");
            text.find(".pc-s-channel__comment-text-inner").css("height", "42px");
        }
    });
}

function taskToggle() {
    $(".js-task-toggle").on("click", function () {
        $(this).stop().toggleClass("active");
        $(this).next(".pc-task__content-drop").stop().slideToggle(300);
    });
    $(".js-task-btn").on("click", function () {
        var task = $(this).closest(".pc-task");
        task.find(".pc-task__drop").stop().slideToggle(300);
        if (task.hasClass("active")) {
            setTimeout(function () {
                task.stop().removeClass("active");
            }, 300);
        }
        else {
            $(".pc-task__drop").not(task.find(".pc-task__drop")).stop().slideUp(300);
            setTimeout(function () {
                $(".pc-task").not(task).stop().removeClass("active");
            }, 300);
            task.stop().addClass("active");
        }
    });
}

function closeActiveTaskForm() {
    var task = $(".pc-task.active");
    task.find(".pc-task__drop").stop().slideToggle(300);
    if (task.hasClass("active")) {
        setTimeout(function () {
            task.stop().removeClass("active");
        }, 300);
    }
}

function sidebar() {
    $(".js-pc-sidebar").on("click", function () {
        if ($(".pc-sidebar").hasClass("active")) {
            $(".pc-sidebar").stop().removeClass("animate");
            setTimeout(function () {
                $(".pc-sidebar").stop().removeClass("active");
            }, 300);
        }
        else {
            if ($(".popup").hasClass("active")) {
                $(".js-popup-close").trigger("click");
                setTimeout(function () {
                    $(".pc-sidebar").stop().addClass("active animate");
                }, 300);
            }
            else {
                $(".pc-sidebar").stop().addClass("active animate");
            }
        }
    });
}

function datepicker() {
    $(".pc-date__time-item").on("click", function () {
        $(this).closest(".pc-date__time").find(".pc-date__time-item").removeClass("active");
        $(this).addClass("active");
    });
    $(document).on("click", function (e) {
        if (!$(".js-dropdown").is(e.target) && $(".js-dropdown").has(e.target).length === 0) {
            $(".js-dropdown").removeClass("active");
        }
    });
    $.fn.datepicker.language['ru'] = {
        days: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
        daysShort: ['Вос', 'Пон', 'Вто', 'Сре', 'Чет', 'Пят', 'Суб'],
        daysMin: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
        months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
        monthsShort: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
        today: 'Сегодня',
        clear: 'Очистить',
        dateFormat: 'dd.mm.yyyy',
        timeFormat: 'hh:ii',
        firstDay: 1
    };
    $(".js-datepicker").each(function (i, item) {
        $(item).datepicker({
            todayButton: new Date(),
            clearButton: true,
            autoClose: false,
            ignoreReadonly: true,
            allowInputToggle: true,
            minDate: $(item).data('current-min-date') ? new Date() : '',
            timepicker: $(item).data('with-timepicker'),
            onlyTimepicker: $(item).data('only-timepicker'),
            onRenderCell: function (date, cellType) {
                var currentDate = new Date();
                var prevDate = new Date();
                var prevMonth = new Date();
                var prevYear = new Date();
                prevDate.setDate(currentDate.getDate() - 1);
                prevMonth.setMonth(currentDate.getMonth() - 1);
                prevYear.setFullYear(currentDate.getFullYear() - 1);
                if (cellType == 'day') {
                    if (prevDate.getDate() == date.getDate() && prevDate.getMonth() == date.getMonth() && prevDate.getYear() == date.getYear()) {
                        return {
                            classes: '-lastDisabled-'
                        }
                    }
                }
                else if (cellType == 'month') {
                    if (prevMonth.getMonth() == date.getMonth() && prevMonth.getYear() == date.getYear()) {
                        return {
                            classes: '-lastDisabled-'
                        }
                    }
                }
                else if (cellType == 'year') {
                    if (prevYear.getYear() == date.getYear()) {
                        return {
                            classes: '-lastDisabled-'
                        }
                    }
                }
            },
            onSelect: function () {
                $(item).addClass("focus");
                $(item).trigger("change");
            }
        });
    });
}

function apiItem() {
    $(".js-api-item-toggle").on("click", function () {
        $(this).toggleClass("active");
        $(this).next(".pc-api-item__content").slideToggle(300);
    });
}

function stickyElement() {
    window.sticky = new Sticky('.js-sticky');
    var isDestroy = false;
    $(window).on("resize", function () {
        if ($(window).innerWidth() < 1025 && !isDestroy) {
            isDestroy = true;
            window.sticky.destroy();
        }
    }).trigger("resize");
}

function addFile() {
    $(document).on("change", ".js-addfile-field", function () {
        let id = $(this).attr("id");
        let files = document.getElementById(id).files;
        let itemsCont = $(this).closest(".addfile").find(".addfile-items");
        files.forEach(function(item, i, arr) {
            let el = `<div class="addfile-item" data-id="${id}" title="${item.name}">
                <div class="addfile-item__icon"><span class="svg-image-doc-blue"></span></div>
                <div class="addfile-item__name">${item.name}</div>
            </div>`;
            $(itemsCont).append(el);
        });
        $(itemsCont).addClass("active");
    });
}

function messageText() {

    $('.js-message-text').each(function () {
        var $ellipsisText = $(this);

        while ($ellipsisText.outerHeight(true) > 44) {
            $ellipsisText.text(function (index, text) {
                return text.replace(/\W*\s(\S)*$/, '...');
            });
        }
    });
}

function faqMenu() {
    $(".js-faq-link").on("click", function (e) {
        e.preventDefault();
        $(".js-faq-link.active").not($(this)).next(".pc-faq-menu__drop").slideUp(300)
        $(".js-faq-link.active").not($(this)).removeClass("active");
        $(this).toggleClass("active");
        $(this).next(".pc-faq-menu__drop").slideToggle(300, function () {
            window.sticky.update();
        });
    });
}

function passwordVisible() {
    $(".js-password-toggle").on("click", function () {
        $(this).toggleClass("active");
        var input = $(this).prev("input");
        if (input.attr("type") === "password") {
            input.attr("type", "text");
        } else {
            input.attr("type", "password");
        }
    });
}

function scrollbar() {
    $(".js-scrollbar").each(function (i, item) {
        new SimpleBar($(item)[0], {
            autoHide: false
        });
    })
}

function rangeSlider() {
    $(".js-range-slider").each(function () {
        var $this = $(this),
            $inputFrom = $this.closest(".pc-service-item").find(".js-range-slider-from"),
            $inputTo = $this.closest(".pc-service-item").find(".js-range-slider-to"),
            min = $this.data("min"),
            max = $this.data("max"),
            from = $this.data("from"),
            to = $this.data("to");
        function updateInputs(data) {
            from = data.from;
            $inputFrom.prop("value", from);
            $inputFrom.attr('size', $inputFrom.val().length);
            if ($inputTo.length) {
                to = data.to;
                $inputTo.prop("value", to);
                $inputTo.attr('size', $inputTo.val().length);
            }
        }
        $(this).ionRangeSlider({
            onStart: function (data) {
                updateInputs(data);
                $this.closest(".rangeslider").addClass("active");
                $inputFrom.closest(".pc-service-item__slider-values").addClass("active");
            },
            onChange: updateInputs,
            onFinish: updateInputs,
        });
        $inputFrom.on("input", function () {
            var val = $(this).prop("value");

            if (val < min) {
                val = min;
            } else if (val > $this.data("to")) {
                val = $this.data("to");
            }

            $this.data("ionRangeSlider").update({
                from: val
            });

            $(this).prop("value", val);
            $(this).attr('size', $(this).val().length == 0 ? 1 : $(this).val().length);

        });

        $inputTo.on("input", function () {
            var val = $(this).prop("value");

            if (val < $this.data("from")) {
                val = $this.data("from");
            } else if (val > max) {
                val = max;
            }

            $this.data("ionRangeSlider").update({
                to: val
            });

            $(this).prop("value", val);
            $(this).attr('size', $(this).val().length == 0 ? 1 : $(this).val().length);
        });
    });
}

function cChart() {
    var radius = parseInt($(".js-c-chart").attr("r"));
    var perimeter = 2 * 3.14 * radius;

    $(".js-c-chart").each(function () {
        var amount = parseFloat($(this).attr("data-fill"));
        var fillAmount = perimeter - perimeter * amount / 100;

        $(this).attr({
            "stroke-dasharray": perimeter,
            "stroke-dashoffset": fillAmount
        })
    });
}

function hTaskLayout() {
    hTasks = $(".js-h-tasks").isotope({
        percentPosition: true,
    });
}

function channelPin() {
    $(".js-channel-pin").on("click", function (e) {
        e.preventDefault();
        $(this).closest(".pc-channel").find(".js-channel-pin").toggleClass("active");
    });
}

function select() {
    $("select").each(function () {
        $(this).select2({
            width: "100%",
            minimumResultsForSearch: 6,
            dropdownCssClass: $(this).attr("data-dropClass"),
        });
    })
}

function filter() {
    $(".js-filter").on("click", function () {
        $(this).toggleClass("active");
        $(".pc-filter__inner").slideToggle("active");
        $(".pc-filter").toggleClass("active");
    });
}

function fields() {
    $(".field").on("input", function () {
        if ($(this).val().length > 0) {
            $(this).addClass("focus");
        }
        else {
            $(this).removeClass("focus")
        }
    })
}

function dropdown() {
    $(".js-dropdown .dropdown-btn").on("click", function () {
        $(".js-dropdown").not($(this).closest(".js-dropdown")).removeClass("active");
        $(this).closest(".js-dropdown").toggleClass("active");
    });
    $(document).on("click", function (e) {
        if (!$(".js-dropdown").is(e.target) && $(".js-dropdown").has(e.target).length === 0) {
            $(".js-dropdown").removeClass("active");
        }
    });
}

function headerMenu() {
    $(".js-header-menu").on("click", function () {
        $(this).stop().toggleClass("active");
        $(".header-menu").stop().slideToggle(300);
    });
    $(window).on("resize", () => {
        if ($(window).innerWidth() > 767) {
            $(".header-menu").removeAttr("style");
            $(".js-header-menu").removeClass("active");
        }
    }).trigger("resize");
}

function fixedHeader() {
    if ($(window).scrollTop() > 0) {
        $(".header").addClass("fixed");
    }
    else {
        $(".header").removeClass("fixed");
    }
}

function tabs() {
    $(".tabs-btn").on("click", function (e) {
        e.preventDefault();
        $(this).closest(".tabs").find(".tabs-btn, .tabs-item").removeClass("active");
        $(this).addClass("active");
        $(this).closest(".tabs").find(".tabs-item").eq($(this).index()).addClass("active");
    });
}

function addAlert(type, text) {
    let alert = $('<div class="alert alert__' + type + '">' +
        text +
        '<button class="close" type="button" data-dismiss="alert" aria-hidden="true">×</button>' +
        '</div>');

    $('#alerts').append(alert);
    setTimeout(function() {
        $(alert).find('.close').trigger('click');
    }, 30000);
}

function alert() {
    $(document).on('click', '#alerts .alert button.close', function (e) {
        e.preventDefault();
        $(this).closest('.alert').remove();
    })
}

function confirmDialog() {
    $(document).on('click', '[data-confirm]', function () {
        return confirm($(this).attr("data-confirm"));
    })
}

function jsonRpcRequest(url, method, params, beforeSendCallback, successCallback, errorCallback) {
    if (window.process) {
        window.process.abort();
    }
    try {
        window.process = $.ajax({
            url: url,
            type: 'POST',
            data: JSON.stringify({
                'jsonrpc': '2.0',
                'method': method,
                'id': '1',
                'params': params
            }),
            dataType: 'json',
            beforeSend: function () {
                beforeSendCallback();
            },
            success: function(result) {
                successCallback(result);
            },
            error: function (xhr, ajaxOptions, thrownError) {
                errorCallback(xhr.status);
            }
        });
    } catch (e) {
        console.log(e);
    }

}

function api() {
    $(document).on('submit', 'form[data-api-method]', function (e) {
        e.preventDefault();
        let url = window.apiUrl ?? '/json-rpc';
        let method = $(this).data('api-method');
        let params = {};

        $(this).serializeArray().forEach(function (item) {
            params[item.name] = item.value;
        });

        jsonRpcRequest(
            url,
            method,
            params,
            function() {
                $('.error').removeClass('error');
                $('[data-error]').attr('data-error', '');
            },
            function(result) {
                if (result.result) {
                    if (result.result.alerts) {
                        for (let alertType in result.result.alerts) {
                            if (result.result.alerts.hasOwnProperty(alertType)) {
                                for (let alertMessage of result.result.alerts[alertType]) {
                                    addAlert(alertType, alertMessage);
                                }
                            }
                        }
                    }
                    closeActivePopup();
                    closeActiveTaskForm();
                    if (result.result.url) {
                        window.location.replace(result.result.url);
                    }
                }
                if (result.error) {
                    data = result.error.data;
                    if (data.params_errors) {
                        for (let field in data.params_errors) {
                            let input = $('[name=' + field + ']');
                            $(input).addClass('error');
                            $(input).closest('.popup-label').attr('data-error', data.params_errors[field]).addClass('error');
                        }
                    } else {
                        addAlert('danger', data.human_message !== "" ? data.human_message : result.error.message);
                    }
                }
            },
            function(status) {
                if (status) {
                    addAlert('danger', 'Form send problem: ' + status);
                }
            }
        )
    })
}